import { Fragment, memo } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import {
  SIDEBAR_MENU_ITEMS,
  SidebarMenu,
  SidebarMenuItem,
} from "./sidebar.constants";
import { useAuthContext } from "../../providers";

const SidebarDrawerFC = () => {
  const { logout } = useAuthContext();
  const renderMenuItem = (
    { title, icon: Icon, route }: SidebarMenuItem,
    index: number
  ) => (
    <ListItem button component={Link} to={route} key={`${title}-${index}`}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
  const renderMenu = ({ title, items }: SidebarMenu, index: number) => {
    const subheader = title ? (
      <ListSubheader component="div" id={`subheader-${title}`}>
        {title}
      </ListSubheader>
    ) : undefined;

    return (
      <Fragment key={`${title}-${index}`}>
        <List {...{ subheader }}>{items.map(renderMenuItem)}</List>
        <Divider />
      </Fragment>
    );
  };

  return (
    <div>
      <Toolbar />
      <Divider />
      {SIDEBAR_MENU_ITEMS.map(renderMenu)}
      <ListItem button onClick={logout}>
        <ListItemIcon>
          <LogoutRoundedIcon />
        </ListItemIcon>
        <ListItemText primary="Log out" />
      </ListItem>
    </div>
  );
};

export const SidebarDrawer = memo(SidebarDrawerFC);
