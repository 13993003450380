import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { defaultTheme } from "./app.constants";

import { AuthProvider } from "../reusable/providers";
import { QueryClient, QueryClientProvider } from "react-query";
import { CookiesProvider } from "react-cookie";
import { SnackbarProvider } from "notistack";
import { AppRoutes } from "../reusable/components";

export const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false } },
  });

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarProvider>
            <CssBaseline />
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
};
