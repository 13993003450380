import { useCallback, useMemo, useRef, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { noop } from "lodash";
import { imageFileUploadStyles } from "./image-file-upload-styles";

export type ImageFileUploadProps = {
  imageUrl?: string;
  onChange?: (file: File) => void;
};

export const ImageFileUpload = ({
  imageUrl,
  onChange = noop,
}: ImageFileUploadProps) => {
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const classes = imageFileUploadStyles();

  const imageSrc = useMemo(
    () => (file ? URL.createObjectURL(file) : imageUrl),
    [file, imageUrl]
  );

  const onFileChange = useCallback(
    ({
      target: {
        files: [file],
      },
    }) => {
      setFile(file);
      onChange(file);
    },
    [onChange]
  );

  const onUploadFileClick = () => fileInputRef.current?.click();

  return (
    <div>
      <Typography variant="body1" gutterBottom>
        Gambar
      </Typography>
      <Box display="flex" alignItems="center">
        <img src={imageSrc} alt="img-upload" className={classes.image} />
        <input
          id="image-file-input"
          type="file"
          onChange={onFileChange}
          ref={fileInputRef}
          className={classes.fileInput}
        />
        <Button
          variant="contained"
          type="button"
          onClick={onUploadFileClick}
          sx={{ ml: 3 }}
        >
          {imageUrl ? "Ganti gambar" : "Pilih gambar"}{" "}
        </Button>
      </Box>
    </div>
  );
};
