import axios from "./api";
import { BaseResponseData } from "./api.types";
import { useMutation } from "react-query";

export type SignInRequest = {
  email: string;
  password: string;
};

export type SignInResponse = {
  token: string;
};

export const signIn = (data: SignInRequest) =>
  axios
    .post<BaseResponseData<SignInResponse>>("/login", data)
    .then((response) => {
      console.log(response);
      return response.data.data;
    });
