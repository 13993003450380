import { Box, Button, Paper, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import { Modal } from "../../reusable/components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { KTable, KTableColumnType } from "../../reusable/components/ktable";
import {
  deleteMaterial,
  getMaterials,
  Material,
  MATERIAL_QUERY_KEY,
} from "../material-api";
import { MaterialModify } from "../modify";
import { IdName } from "../../reusable/types";

export const MaterialList = () => {
  const { data } = useQuery(MATERIAL_QUERY_KEY, getMaterials);
  const { mutateAsync: removeMaterial } = useMutation(deleteMaterial);
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState<Material>();
  const [isModifyDialogOpen, setModifyDialogOpen] = useState(false);
  const showModifyDialog = () => setModifyDialogOpen(true);
  const closeModifyDialog = () => setModifyDialogOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const onEditClick = useCallback(
    (item: Material) => {
      setSelectedItem(item);
      showModifyDialog();
    },
    [setSelectedItem]
  );

  const onDeleteClick = useCallback(
    ({ id }: Material) => {
      removeMaterial(id, {
        onSuccess: () => queryClient.invalidateQueries(MATERIAL_QUERY_KEY),
      })
        .then(() =>
          enqueueSnackbar("Bahan berhasil dihapus", { variant: "success" })
        )
        .catch(() =>
          enqueueSnackbar("Gagal menghapus bahan", { variant: "error" })
        );
    },
    [removeMaterial, queryClient, enqueueSnackbar]
  );

  const onModalFormSubmitted = useCallback(() => {
    closeModifyDialog();
    enqueueSnackbar("Bahan berhasil disimpan.", { variant: "success" });
  }, [enqueueSnackbar]);

  const tableColumns: KTableColumnType<Material>[] = [
    {
      dataField: "id",
      label: "ID",
    },
    {
      dataField: "name",
      label: "Nama",
    },
    {
      dataField: "description",
      label: "Deskripsi",
    },
    {
      dataField: "unit",
      label: "Satuan",
    },
    {
      dataField: "unit_amount",
      label: "Jumlah Satuan",
    },
    {
      dataField: "roll_amount",
      label: "Jumlah Roll",
    },
    {
      dataField: "category",
      label: "Kategori",
      formatLabel: (cellData: IdName) => cellData.name,
    },
    {
      dataField: "color",
      label: "Warna",
      formatLabel: (cellData: IdName) => cellData.name,
    },
  ];

  return (
    <>
      <Modal
        title="Tambah Bahan"
        open={isModifyDialogOpen}
        onClose={closeModifyDialog}
      >
        <MaterialModify
          {...{ item: selectedItem, onSubmitted: onModalFormSubmitted }}
        />
      </Modal>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Bahan</Typography>
        <Button variant="contained" onClick={showModifyDialog}>
          Tambah
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 4 }}>
          <KTable
            {...{ data, onEditClick, onDeleteClick, columns: tableColumns }}
          />
        </Paper>
      </Box>
    </>
  );
};
