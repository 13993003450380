import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  IdName,
  IdNameAutocomplete,
  ModalFormProps,
} from "../../reusable/types";
import { noop } from "lodash";
import { useCallback, useMemo } from "react";
import { AxiosError } from "axios";
import { BaseResponseData } from "../../reusable/api/api.types";
import {
  CATEGORY_QUERY_KEY,
  COLOR_QUERY_KEY,
  createMaterial,
  getCategories,
  getColors,
  Material,
  MATERIAL_QUERY_KEY,
  MaterialUnits,
  updateMaterial,
} from "../material-api";

export const MaterialModify = ({
  item,
  onSubmitted = noop,
}: ModalFormProps<Material>) => {
  const { data: colors = [], isLoading: isColorsLoading } = useQuery(
    COLOR_QUERY_KEY,
    getColors
  );
  const { data: categories = [], isLoading: isCategoriesLoading } = useQuery(
    CATEGORY_QUERY_KEY,
    getCategories
  );
  const {
    mutateAsync: saveMaterial,
    isLoading,
    error: submitError,
  } = useMutation(item ? updateMaterial : createMaterial);
  const queryClient = useQueryClient();
  const colorAutocompleteOptions = colors.map(
    (color) => ({ ...color } as IdNameAutocomplete)
  );
  const categoryAutocompleteOptions = categories.map(
    (category) => ({ ...category } as IdNameAutocomplete)
  );
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...item } });

  const errorMessage = useMemo(
    () =>
      submitError
        ? (submitError as AxiosError<BaseResponseData<any>>)?.response?.data
            ?.message
        : Object.values(errors).join(", "),
    [errors, submitError]
  );

  const filter = createFilterOptions<IdNameAutocomplete>();

  const onSubmit: SubmitHandler<Material> = (values) => {
    // console.log("Submitted", values);
    saveMaterial(
      { ...values, id: item?.id ?? 0 },
      {
        onSuccess: () => queryClient.invalidateQueries(MATERIAL_QUERY_KEY),
      }
    ).then((data) => {
      reset();
      onSubmitted(data);
    });
  };

  const textFields = [
    {
      name: "name",
      label: "Nama bahan",
    },
    {
      name: "description",
      label: "Deskripsi",
    },
  ];

  const renderTextField = (
    { name, label }: { name: string; label: string },
    index: number
  ) => (
    <Grid item key={`${name}-${index}`}>
      <Controller
        {...{
          control,
          name,
          render: ({ field }) => (
            <TextField
              {...{
                variant: "outlined",
                label,
                fullWidth: true,
                ...field,
              }}
            />
          ),
        }}
      />
    </Grid>
  );
  const renderOption = useCallback(
    ({ id, name }: IdName, index: number) => (
      <MenuItem value={id} key={`select-option-${name}-${id}-${index}`}>
        {name}
      </MenuItem>
    ),
    []
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} direction="column">
        {textFields.map(renderTextField)}
        <Grid item sx={{ mb: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="material-unit">Satuan</InputLabel>
            <Controller
              name="unit"
              control={control}
              render={({ field }) => (
                <Select labelId="material-unit" {...field} label="Satuan">
                  {MaterialUnits.map((unit) => (
                    <MenuItem value={unit}>{unit}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item sx={{ mb: 1 }}>
          <Controller
            name="color"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, item) => onChange(item)}
                value={value}
                freeSolo
                clearOnBlur
                selectOnFocus
                options={colorAutocompleteOptions}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: 0,
                      inputValue,
                      name: `Tambah warna "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Warna" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name="unit_amount"
            control={control}
            {...{
              render: ({ field }) => (
                <TextField
                  {...{
                    variant: "outlined",
                    label: "Jumlah Satuan",
                    fullWidth: true,
                    ...field,
                  }}
                />
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            name="roll_amount"
            control={control}
            {...{
              render: ({ field }) => (
                <TextField
                  {...{
                    variant: "outlined",
                    label: "Jumlah roll",
                    fullWidth: true,
                    ...field,
                  }}
                />
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            name="category"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, item) => onChange(item)}
                value={value}
                freeSolo
                clearOnBlur
                selectOnFocus
                options={categoryAutocompleteOptions}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      id: 0,
                      inputValue,
                      name: `Tambah warna "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Kategori" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Typography color="error" variant="body1" textAlign="center">
            {Object.values(errors).join(", ") || errorMessage}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ ml: "auto" }}
            disabled={isLoading}
          >
            {isLoading ? "Sedang menyimpan..." : "Simpan"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
