import { FC, PropsWithChildren } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { ModalTitle } from "./modal-title";

export type ModalProps = {
  title: string;
  open: boolean;
  onClose(): void;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  open,
  onClose,
  children,
}) => {
  return (
    <Dialog {...{ open, onClose, maxWidth: "sm", fullWidth: true }}>
      <ModalTitle {...{ onClose }}>{title}</ModalTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};
