import { FC, useEffect, useMemo } from "react";
import { useSigninStyles } from "./use-signin-styles";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAuthContext } from "../../reusable/providers";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { signIn, SignInRequest } from "../../reusable/api";
import { AxiosError } from "axios";
import { BaseResponseData } from "../../reusable/api/api.types";

export const Signin: FC = () => {
  const {
    mutateAsync: performSignIn,
    error: signInError,
    isLoading,
  } = useMutation(signIn);
  const classes = useSigninStyles();
  const { saveToken } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const errorMessage = useMemo(
    () =>
      signInError
        ? (signInError as AxiosError<BaseResponseData<any>>)?.response?.data
            ?.message
        : Object.values(errors).join(", "),
    [errors, signInError]
  );

  const onSubmit: SubmitHandler<SignInRequest> = (data) => {
    performSignIn(data).then((response) => saveToken(response.token));
  };

  return (
    <div className={classes.root}>
      <div className={classes.formWrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h4" textAlign="center" gutterBottom>
                Konveksi
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Email"
                size="small"
                fullWidth
                {...register("email")}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Password"
                type="password"
                size="small"
                fullWidth
                {...register("password")}
              />
            </Grid>
            {errorMessage && (
              <Grid item>
                <Typography color="error" variant="body1" textAlign="center">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Login"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
