import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "../reusable/components";

export const Admin: FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
