export const ROUTES = {
  admin: "admin",
  dashboard: "dashboard",
  material: "material",
  pattern: "pattern",
  product: "product",
  supplier: "supplier",
  supervisor: "supervisor",
  worker: "worker",
  workingProcess: "working-process",
  materialOrder: "material-order",
  productOrder: "product-order",
} as const;
