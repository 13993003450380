import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import { ROUTES } from "../../constants";
import { ElementType } from "react";

export type SidebarMenu = {
  title?: string;
  items: SidebarMenuItem[];
};

export type SidebarMenuItem = {
  title: string;
  icon: ElementType;
  route: string;
};

export const SIDEBAR_MENU_ITEMS: SidebarMenu[] = [
  {
    items: [
      {
        title: "Dashboard",
        icon: DashboardRoundedIcon,
        route: ROUTES.dashboard,
      },
    ],
  },
  {
    title: "Master Data",
    items: [
      {
        title: "Proses",
        icon: CircleOutlinedIcon,
        route: ROUTES.workingProcess,
      },
      {
        title: "Bahan",
        icon: CircleOutlinedIcon,
        route: ROUTES.material,
      },
      {
        title: "Pola",
        icon: CircleOutlinedIcon,
        route: ROUTES.pattern,
      },
      {
        title: "Produk",
        icon: CircleOutlinedIcon,
        route: ROUTES.product,
      },
    ],
  },
  {
    title: "HR",
    items: [
      // {
      //   title: "Supplier",
      //   icon: CircleOutlinedIcon,
      //   route: ROUTES.supplier,
      // },
      {
        title: "Supervisor",
        icon: CircleOutlinedIcon,
        route: ROUTES.supervisor,
      },
      {
        title: "Karyawan",
        icon: CircleOutlinedIcon,
        route: ROUTES.worker,
      },
    ],
  },
  {
    title: "Transaksi",
    items: [
      {
        title: "Pesanan Bahan",
        icon: CircleOutlinedIcon,
        route: ROUTES.materialOrder,
      },
      {
        title: "Rekap Order Produksi",
        icon: CircleOutlinedIcon,
        route: ROUTES.productOrder,
      },
    ],
  },
];
