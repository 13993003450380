import { Box, Container, Toolbar } from "@mui/material";
import { memo, useState, FC, PropsWithChildren } from "react";
import { DRAWER_WIDTH } from "../../constants";
import { Header } from "../header";
import { Sidebar } from "../sidebar";

const LayoutFC: FC<PropsWithChildren<any>> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Header {...{ handleDrawerToggle }} />
      <Sidebar {...{ mobileOpen, handleDrawerToggle }} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
      >
        <Toolbar />
        <Container maxWidth="lg">
          <Box sx={{ paddingTop: 8 }}>{children}</Box>
        </Container>
      </Box>
    </Box>
  );
};

export const Layout = memo(LayoutFC);
