import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  createFilterOptions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AutocompleteFieldType,
  IdName,
  IdNameAutocomplete,
  ModalFormProps,
  TextFieldType,
} from "../../reusable/types";
import { noop } from "lodash";
import { useMemo, useState } from "react";
import { AxiosError } from "axios";
import { BaseResponseData } from "../../reusable/api/api.types";
import { ImageFileUpload } from "../../reusable/components/image-file-upload";
import {
  createProduct,
  Product,
  PRODUCT_QUERY_KEY,
  updateProduct,
} from "../product-api";
import {
  getWorkingProcesses,
  WORKING_PROCESS_QUERY_KEY,
  WorkingProcess,
} from "../../working-process/working-process-api";
import {
  getMaterials,
  Material,
  MATERIAL_QUERY_KEY,
} from "../../material/material-api";
import {
  getPatterns,
  Pattern,
  PATTERN_QUERY_KEY,
} from "../../pattern/pattern-api";

export const ProductModify = ({
  item,
  onSubmitted = noop,
}: ModalFormProps<Product>) => {
  const {
    mutateAsync: saveProduct,
    isLoading,
    error: submitError,
  } = useMutation(item ? updateProduct : createProduct);
  const { data: workingProcesses = [] } = useQuery(
    WORKING_PROCESS_QUERY_KEY,
    getWorkingProcesses
  );
  const { data: materials = [] } = useQuery(MATERIAL_QUERY_KEY, getMaterials);
  const { data: patterns = [] } = useQuery(PATTERN_QUERY_KEY, getPatterns);
  const queryClient = useQueryClient();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...item } });
  const [imageFile, setImageFile] = useState<File>();
  const imageUrl = typeof item?.image === "string" ? item?.image : undefined;
  const errorMessage = useMemo(
    () =>
      submitError
        ? (submitError as AxiosError<BaseResponseData<any>>)?.response?.data
            ?.message
        : Object.values(errors).join(", "),
    [errors, submitError]
  );
  const filterPattern = createFilterOptions<Pattern>();
  const filterMaterial = createFilterOptions<Material>();
  const filterWorkingProcess = createFilterOptions<WorkingProcess>();

  const onSubmit: SubmitHandler<Product> = (values) => {
    saveProduct(
      { ...values, id: item?.id ?? 0, image: imageFile },
      {
        onSuccess: () => queryClient.invalidateQueries(PRODUCT_QUERY_KEY),
      }
    ).then((data) => {
      reset();
      onSubmitted(data);
    });
  };

  const textFields: TextFieldType[] = [
    {
      name: "name",
      label: "Nama Produk",
    },
    {
      name: "description",
      label: "Deskripsi",
    },
  ];

  const autoCompleteFields = [
    {
      name: "pattern",
      label: "Jenis pola",
      options: patterns,
    },
    {
      name: "material",
      label: "bahan",
      options: materials,
    },
    {
      name: "working_process",
      label: "Proses",
      options: workingProcesses,
    },
  ];

  const renderTextField = ({ name, label }: TextFieldType, index: number) => (
    <Grid item key={`${name}-${index}`}>
      <Controller
        {...{
          control,
          name,
          render: ({ field }) => (
            <TextField
              {...{
                variant: "outlined",
                label,
                fullWidth: true,
                ...field,
              }}
            />
          ),
        }}
      />
    </Grid>
  );

  // const renderAutocomplete = (
  //   { name, label, options }: AutocompleteFieldType,
  //   index: number
  // ) => (
  //   <Grid item key={`${name}-${index}`}>
  //     <Controller
  //       {...{ name, label, options }}
  //       render={({ field: { onChange, value } }) => (
  //         <Autocomplete
  //           onChange={(_, item) => onChange(item)}
  //           value={value}
  //           freeSolo
  //           clearOnBlur
  //           selectOnFocus
  //           getOptionLabel={(option) => option.name}
  //           filterOptions={(options, params) => filter(options, params)}
  //           renderOption={(props, option) => <li {...props}>{option.name}</li>}
  //           renderInput={(params) => <TextField {...params} label={name} />}
  //         />
  //       )}
  //     />
  //   </Grid>
  // );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} direction="column">
        {textFields.map(renderTextField)}
        <Grid item>
          <Controller
            name="pattern"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                options={patterns}
                onChange={(_, item) => onChange(item)}
                value={value}
                clearOnBlur
                selectOnFocus
                getOptionLabel={(option) => option.name}
                filterOptions={(options, params) =>
                  filterPattern(options, params)
                }
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => <TextField {...params} label="Pola" />}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name="material"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, item) => onChange(item)}
                value={value}
                options={materials}
                clearOnBlur
                selectOnFocus
                getOptionLabel={(option) => option.name}
                filterOptions={(options, params) =>
                  filterMaterial(options, params)
                }
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Bahan" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Controller
            name="processes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(_, item) => onChange(item)}
                value={value}
                options={workingProcesses}
                multiple
                clearOnBlur
                selectOnFocus
                getOptionLabel={(option) => option.name}
                filterOptions={(options, params) =>
                  filterWorkingProcess(options, params)
                }
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Proses" />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <ImageFileUpload imageUrl={imageUrl} onChange={setImageFile} />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Typography color="error" variant="body1" textAlign="center">
            {Object.values(errors).join(", ") || errorMessage}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ ml: "auto" }}
            disabled={isLoading}
          >
            {isLoading ? "Sedang menyimpan..." : "Simpan"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
