import { Box, Button, Paper, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import { WorkingProcessModify } from "../modify";
import { Modal } from "../../reusable/components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteWorkingProcess,
  getWorkingProcesses,
  WORKING_PROCESS_QUERY_KEY,
  WorkingProcess,
} from "../working-process-api";
import { useSnackbar } from "notistack";
import { KTable, KTableColumnType } from "../../reusable/components/ktable";

export const WorkingProcessList = () => {
  const { data } = useQuery(WORKING_PROCESS_QUERY_KEY, getWorkingProcesses);
  const { mutateAsync: removeWorkingProcess, isLoading } =
    useMutation(deleteWorkingProcess);
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState<WorkingProcess>();

  const [isModifyDialogOpen, setModifyDialogOpen] = useState(false);
  const showModifyDialog = () => setModifyDialogOpen(true);
  const closeModifyDialog = () => setModifyDialogOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const onEditClick = useCallback(
    (item: WorkingProcess) => {
      setSelectedItem(item);
      showModifyDialog();
    },
    [setSelectedItem]
  );

  const onDeleteClick = useCallback(
    ({ id }: WorkingProcess) => {
      removeWorkingProcess(id, {
        onSuccess: () =>
          queryClient.invalidateQueries(WORKING_PROCESS_QUERY_KEY),
      })
        .then(() =>
          enqueueSnackbar("Proses berhasil dihapus", { variant: "success" })
        )
        .catch(() =>
          enqueueSnackbar("Gagal menghapus prosess", { variant: "error" })
        );
    },
    [removeWorkingProcess, queryClient, enqueueSnackbar]
  );

  const onModalFormSubmitted = useCallback(() => {
    closeModifyDialog();
    enqueueSnackbar("Proses berhasil disimpan.", { variant: "success" });
  }, [enqueueSnackbar]);

  const tableColumns: KTableColumnType<WorkingProcess>[] = [
    {
      dataField: "id",
      label: "ID",
    },
    {
      dataField: "name",
      label: "Nama",
    },
    {
      dataField: "description",
      label: "Deskripsi",
    },
    {
      dataField: "cost",
      label: "Biaya",
    },
  ];

  return (
    <>
      <Modal
        title="Tambah proses"
        open={isModifyDialogOpen}
        onClose={closeModifyDialog}
      >
        <WorkingProcessModify
          {...{ item: selectedItem, onSubmitted: onModalFormSubmitted }}
        />
      </Modal>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Proses</Typography>
        <Button variant="contained" onClick={showModifyDialog}>
          Tambah
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 4 }}>
          <KTable
            {...{ data, onEditClick, onDeleteClick, columns: tableColumns }}
          />
        </Paper>
      </Box>
    </>
  );
};
