import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Signin } from "../../../onboarding";

export const PublicRoute: FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<Signin />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );
};
