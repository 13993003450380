import api from "../reusable/api/api";
import { BaseResponseData } from "../reusable/api/api.types";
import { IdName } from "../reusable/types";

export type WorkingProcess = {
  description: string;
  cost: number;
} & IdName;

export const WORKING_PROCESS_QUERY_KEY = "working_process";

export const WORKING_PROCESS_PATH = "/working-processes";

export const getWorkingProcesses = () =>
  api
    .get<BaseResponseData<WorkingProcess[]>>(WORKING_PROCESS_PATH)
    .then((response) => response.data?.data ?? []);

export const createWorkingProcess = (request: WorkingProcess) =>
  api
    .post<BaseResponseData<WorkingProcess>>(WORKING_PROCESS_PATH, request)
    .then((response) => response.data?.data);

export const updateWorkingProcess = (request: WorkingProcess) =>
  api
    .put<BaseResponseData<WorkingProcess>>(
      `${WORKING_PROCESS_PATH}/${request.id}`,
      request
    )
    .then((response) => response.data?.data);

export const deleteWorkingProcess = (id: number): Promise<void> =>
  api.delete(`${WORKING_PROCESS_PATH}/${id}`).then((response) => response.data);
