import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useSigninStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      background: theme.palette.background.default,
    },
    formWrapper: {
      width: 300,
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "column",
      padding: 30,
      background: theme.palette.background.paper,
    },
  }),
  { name: "signin" }
);
