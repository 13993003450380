import api from "../reusable/api/api";
import { BaseResponseData } from "../reusable/api/api.types";
import { IdName, IdNameAutocomplete } from "../reusable/types";

export const MaterialUnits = ["KG", "YARD", "METER"] as const;
export type MaterialUnitType = typeof MaterialUnits[number];

export type Material = {
  description: string;
  unit: MaterialUnitType;
  unit_amount: number;
  roll_amount: number;
  category: IdNameAutocomplete;
  color: IdNameAutocomplete;
} & IdName;

export const MATERIAL_QUERY_KEY = "materials";
export const COLOR_QUERY_KEY = "colors";
export const CATEGORY_QUERY_KEY = "categories";

export const MATERIAL_PATH = "/materials";
export const COLOR_PATH = "/colors";
export const CATEGORY_PATH = "/categories";

export const getMaterials = () =>
  api
    .get<BaseResponseData<Material[]>>(MATERIAL_PATH)
    .then((response) => response.data?.data ?? []);

export const getColors = () =>
  api
    .get<BaseResponseData<IdName[]>>(COLOR_PATH)
    .then((response) => response.data?.data ?? []);

export const getCategories = () =>
  api
    .get<BaseResponseData<IdName[]>>(CATEGORY_PATH)
    .then((response) => response.data?.data ?? []);

export const createMaterial = (request: Material) =>
  api
    .post<BaseResponseData<Material>>(MATERIAL_PATH, request)
    .then((response) => response.data?.data);

export const updateMaterial = (request: Material) =>
  api
    .put<BaseResponseData<Material>>(`${MATERIAL_PATH}/${request.id}`, request)
    .then((response) => response.data?.data);

export const deleteMaterial = (id: number): Promise<void> =>
  api.delete(`${MATERIAL_PATH}/${id}`).then((response) => response.data);
