import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC, PropsWithChildren } from "react";

export type ModalTitleProps = {
  onClose(): void;
};

export const ModalTitle: FC<PropsWithChildren<ModalTitleProps>> = ({
  onClose,
  children,
}) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
