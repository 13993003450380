import {
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { get, noop } from "lodash";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MouseEvent, ReactNode, useCallback, useState } from "react";

export type KTableColumnType<T> = {
  dataField: string;
  label: string;
  formatLabel?: (data: any, item: T) => ReactNode;
};

export type KTableProps<T> = {
  data?: T[];
  columns: KTableColumnType<T>[];
  onEditClick?(item: T): void;
  onDeleteClick?(item: T): void;
};

export const KTable = <T,>({
  data = [],
  columns,
  onEditClick = noop,
  onDeleteClick = noop,
}: KTableProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<T>();
  const isMenuOpen = Boolean(anchorEl);

  const showTableRowMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeTableRowMenu = () => {
    setAnchorEl(null);
    return true;
  };

  const onMoreButtonClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      showTableRowMenu(event);
      const { index = "0" } = event.currentTarget.dataset;
      setSelectedItem(data[parseInt(index)]);
    },
    [data]
  );

  const onEditItemClick = useCallback(
    (item: T) => {
      closeTableRowMenu();
      onEditClick(item);
    },
    [onEditClick]
  );

  const onDeleteItemClick = useCallback(
    (item: T) => {
      closeTableRowMenu();
      onDeleteClick(item);
    },
    [onDeleteClick]
  );

  const renderTableColumnHeader = (
    { label, dataField }: KTableColumnType<T>,
    index: number
  ) => (
    <TableCell
      sx={{ borderBottom: 1, borderColor: "grey.500" }}
      key={`table-header-column-${dataField}-${index}`}
    >
      {label}
    </TableCell>
  );

  const renderTableRowCell = useCallback(
    (
      item: T,
      { label, dataField, formatLabel }: KTableColumnType<T>,
      index: number
    ) => {
      const cellData = get(item, dataField);

      return (
        <TableCell key={`table-cell-${label}-${index}`}>
          {formatLabel ? formatLabel(cellData, item) : cellData}
        </TableCell>
      );
    },
    []
  );

  const renderTableRow = useCallback(
    (item: T, index: number) => {
      return (
        <TableRow key={`ktable-row-${index}`}>
          {columns.map((column, index) =>
            renderTableRowCell(item, column, index)
          )}
          <TableCell sx={{ width: "30px" }}>
            <IconButton
              id={`ktable-menu-button-${index}`}
              onClick={onMoreButtonClick}
              data-index={index}
            >
              <MoreVertIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    },
    [columns, onMoreButtonClick, renderTableRowCell]
  );

  return (
    <>
      <Toolbar sx={{ mb: 2, justifyContent: "end" }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Ketik untuk cari..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {columns.map(renderTableColumnHeader)}
              <TableCell sx={{ borderBottom: 1, borderColor: "grey.500" }} />
            </TableRow>
          </TableHead>
          <TableBody>{data.map(renderTableRow)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length ?? 0}
        rowsPerPage={10}
        page={0}
        onPageChange={noop}
        onRowsPerPageChange={noop}
        sx={{ mt: 2 }}
      />
      <Menu
        id={`ktable-action-menu`}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeTableRowMenu}
      >
        <MenuItem onClick={() => selectedItem && onEditItemClick(selectedItem)}>
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => selectedItem && onDeleteItemClick(selectedItem)}
        >
          Hapus
        </MenuItem>
      </Menu>
    </>
  );
};
