import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const imageFileUploadStyles = makeStyles((theme: Theme) => ({
  fileInput: { display: "none" },
  image: {
    width: 100,
    minHeight: 100,
    maxHeight: 300,
    background: theme.palette.background.default,
    objectFit: "contain",
  },
}));
