import api from "../reusable/api/api";
import { BaseResponseData } from "../reusable/api/api.types";
import { IdName } from "../reusable/types";

export type Pattern = {
  description: string;
  image?: string | File;
  size: string;
} & IdName;

export const PATTERN_QUERY_KEY = "patterns";

export const PATTERN_PATH = "/patterns";

const mapPatternToFormData = (request: Pattern) => {
  const formData = new FormData();
  formData.append("name", request.name);
  formData.append("description", request.description);
  formData.append("size", request.size);
  if (request.image) {
    formData.append("image", request.image);
  }
  return formData;
};

export const getPatterns = () =>
  api
    .get<BaseResponseData<Pattern[]>>(PATTERN_PATH)
    .then((response) => response.data?.data ?? []);

export const createPattern = (request: Pattern) => {
  return api
    .post<BaseResponseData<Pattern>>(
      PATTERN_PATH,
      mapPatternToFormData(request),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => response.data?.data);
};

export const updatePattern = (request: Pattern) =>
  api
    .post<BaseResponseData<Pattern>>(
      `${PATTERN_PATH}/${request.id}?XDEBUG_SESSION_START=PHPSTORM`,
      mapPatternToFormData(request),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => response.data?.data);

export const deletePattern = (id: number): Promise<void> =>
  api.delete(`${PATTERN_PATH}/${id}`).then((response) => response.data);
