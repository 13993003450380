import { Box, Button, Paper, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import { ProductModify } from "../modify";
import { Modal } from "../../reusable/components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { KTable, KTableColumnType } from "../../reusable/components/ktable";
import {
  deleteProduct,
  getProducts,
  Product,
  PRODUCT_QUERY_KEY,
} from "../product-api";
import { Pattern } from "../../pattern/pattern-api";
import { Material } from "../../material/material-api";
import { WorkingProcess } from "../../working-process/working-process-api";

export const ProductList = () => {
  const { data } = useQuery(PRODUCT_QUERY_KEY, getProducts);
  const { mutateAsync: removeProduct, isLoading } = useMutation(deleteProduct);
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState<Product>();

  const [isModifyDialogOpen, setModifyDialogOpen] = useState(false);
  const showModifyDialog = () => setModifyDialogOpen(true);
  const closeModifyDialog = () => setModifyDialogOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const onAddClick = () => {
    setSelectedItem(undefined);
    showModifyDialog();
  };

  const onEditClick = (item: Product) => {
    setSelectedItem(item);
    showModifyDialog();
  };

  const onDeleteClick = useCallback(
    ({ id }: Product) => {
      removeProduct(id, {
        onSuccess: () => queryClient.invalidateQueries(PRODUCT_QUERY_KEY),
      })
        .then(() =>
          enqueueSnackbar("Produk berhasil dihapus", { variant: "success" })
        )
        .catch(() =>
          enqueueSnackbar("Gagal menghapus produk", { variant: "error" })
        );
    },
    [removeProduct, queryClient, enqueueSnackbar]
  );

  const onModalFormSubmitted = useCallback(() => {
    closeModifyDialog();
    enqueueSnackbar("Produk berhasil disimpan.", { variant: "success" });
  }, [enqueueSnackbar]);

  const tableColumns: KTableColumnType<Product>[] = [
    {
      dataField: "id",
      label: "ID",
    },
    {
      dataField: "image",
      label: "image",
      formatLabel: (imageUrl) => (
        <img alt="img-product" src={imageUrl} width={100} />
      ),
    },
    {
      dataField: "name",
      label: "Nama",
    },
    {
      dataField: "description",
      label: "Deskripsi",
    },
    {
      dataField: "pattern",
      label: "Pola",
      formatLabel: (pattern: Pattern) => pattern.name,
    },
    {
      dataField: "material",
      label: "Bahan",
      formatLabel: (material: Material) => material.name,
    },
    {
      dataField: "processes",
      label: "Proses",
      formatLabel: (processes: WorkingProcess[]) =>
        processes.map((process) => process.name).join(", "),
    },
  ];

  return (
    <>
      <Modal
        title="Tambah Produk"
        open={isModifyDialogOpen}
        onClose={closeModifyDialog}
      >
        <ProductModify
          {...{ item: selectedItem, onSubmitted: onModalFormSubmitted }}
        />
      </Modal>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Produk</Typography>
        <Button variant="contained" onClick={onAddClick}>
          Tambah
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 4 }}>
          <KTable
            {...{ data, onEditClick, onDeleteClick, columns: tableColumns }}
          />
        </Paper>
      </Box>
    </>
  );
};
