import { Box, Button, Paper, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import { PatternModify } from "../modify";
import { Modal } from "../../reusable/components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { KTable, KTableColumnType } from "../../reusable/components/ktable";
import {
  deletePattern,
  getPatterns,
  Pattern,
  PATTERN_QUERY_KEY,
} from "../pattern-api";

export const PatternList = () => {
  const { data } = useQuery(PATTERN_QUERY_KEY, getPatterns);
  const { mutateAsync: removePattern, isLoading } = useMutation(deletePattern);
  const queryClient = useQueryClient();
  const [selectedItem, setSelectedItem] = useState<Pattern>();

  const [isModifyDialogOpen, setModifyDialogOpen] = useState(false);
  const showModifyDialog = () => setModifyDialogOpen(true);
  const closeModifyDialog = () => setModifyDialogOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const onAddClick = () => {
    setSelectedItem(undefined);
    showModifyDialog();
  };

  const onEditClick = (item: Pattern) => {
    setSelectedItem(item);
    showModifyDialog();
  };

  const onDeleteClick = useCallback(
    ({ id }: Pattern) => {
      removePattern(id, {
        onSuccess: () => queryClient.invalidateQueries(PATTERN_QUERY_KEY),
      })
        .then(() =>
          enqueueSnackbar("Pola berhasil dihapus", { variant: "success" })
        )
        .catch(() =>
          enqueueSnackbar("Gagal menghapus pola", { variant: "error" })
        );
    },
    [removePattern, queryClient, enqueueSnackbar]
  );

  const onModalFormSubmitted = useCallback(() => {
    closeModifyDialog();
    enqueueSnackbar("Pola berhasil disimpan.", { variant: "success" });
  }, [enqueueSnackbar]);

  const tableColumns: KTableColumnType<Pattern>[] = [
    {
      dataField: "id",
      label: "ID",
    },
    {
      dataField: "image",
      label: "image",
      formatLabel: (imageUrl) => (
        <img alt="img-pattern" src={imageUrl} width={100} />
      ),
    },
    {
      dataField: "name",
      label: "Nama",
    },
    {
      dataField: "description",
      label: "Deskripsi",
    },
    {
      dataField: "size",
      label: "size",
    },
  ];

  return (
    <>
      <Modal
        title="Tambah Pola"
        open={isModifyDialogOpen}
        onClose={closeModifyDialog}
      >
        <PatternModify
          {...{ item: selectedItem, onSubmitted: onModalFormSubmitted }}
        />
      </Modal>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Pola</Typography>
        <Button variant="contained" onClick={onAddClick}>
          Tambah
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Paper sx={{ p: 4 }}>
          <KTable
            {...{ data, onEditClick, onDeleteClick, columns: tableColumns }}
          />
        </Paper>
      </Box>
    </>
  );
};
