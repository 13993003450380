import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Admin } from "../../../admin";
import { Dashboard } from "../../../dashboard";
import { WorkingProcessList } from "../../../working-process";
import { PatternList } from "../../../pattern";
import { MaterialList } from "../../../material";
import { ProductList, ProductOrder } from "../../../product";
import { SupplierList } from "../../../supplier";
import { SupervisorList } from "../../../supervisor";
import { WorkerList } from "../../../worker";
import { MaterialOrderList } from "../../../material-order";

export const PrivateRoute: FC = () => {
  return (
    <Routes>
      <Route path="admin/*" element={<Admin />}>
        <Route index element={<Dashboard />} />
        <Route path="working-process" element={<WorkingProcessList />} />
        <Route path="material" element={<MaterialList />} />
        <Route path="pattern" element={<PatternList />} />
        <Route path="product" element={<ProductList />} />

        <Route path="supplier" element={<SupplierList />} />
        <Route path="supervisor" element={<SupervisorList />} />
        <Route path="worker" element={<WorkerList />} />

        <Route path="material-order" element={<MaterialOrderList />} />
        <Route path="product-order" element={<ProductOrder />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/admin" />} />
    </Routes>
  );
};
