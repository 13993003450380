import { createTheme, ThemeOptions } from "@mui/material";

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#455a64",
    },
    secondary: {
      main: "#26a69a",
    },
    background: {
      default: "#eceff1",
    },
    text: {
      primary: "#263238",
      secondary: "#9e9e9e",
      disabled: "#d5d5d5",
    },
    divider: "#eceff1",
  },
  typography: {
    fontFamily: "Open Sans, Helvetica, sans-serif",
  },
};

export const defaultTheme = createTheme(themeOptions);
