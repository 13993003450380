import { createContext, FC, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { noop } from "lodash";
import { TOKEN_NAME } from "../constants";
import { useCookies } from "react-cookie";

export type AuthContextType = {
  isLoggedIn: boolean;
  saveToken(token: string): void;
  logout(): void;
};

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  saveToken: noop,
  logout: noop,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
  // const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);
  const initialLoggedIn = Cookies.get(TOKEN_NAME) !== undefined;
  const [isLoggedIn, setLoggedIn] = useState(initialLoggedIn);
  // const isLoggedIn = cookies !== undefined;

  const saveToken = (token: string) => {
    const expires = new Date(Date.now() + 5 * 3600 * 1000);
    // window.localStorage.setItem(TOKEN_NAME);
    // setCookie(TOKEN_NAME, token, { expires });
    Cookies.set(TOKEN_NAME, token, { expires });
    setLoggedIn(true);
  };

  const logout = () => {
    // removeCookie(TOKEN_NAME);
    Cookies.remove(TOKEN_NAME);
    setLoggedIn(false);
  };

  // useEffect(() => {
  //   !!Cookies.get(TOKEN_NAME) && logout();
  // }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, saveToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
