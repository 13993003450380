import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { PublicRoute } from "./public-route";
import { PrivateRoute } from "./private-route";
import { useAuthContext } from "../../providers";

export const AppRoutes: FC = () => {
  const { isLoggedIn } = useAuthContext();

  return (
    <BrowserRouter>
      {!isLoggedIn ? <PublicRoute /> : <PrivateRoute />}
    </BrowserRouter>
  );
};
