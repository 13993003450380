import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { ModalFormProps } from "../../reusable/types";
import { noop } from "lodash";
import { useMemo, useState } from "react";
import { AxiosError } from "axios";
import { BaseResponseData } from "../../reusable/api/api.types";
import {
  createPattern,
  Pattern,
  PATTERN_QUERY_KEY,
  updatePattern,
} from "../pattern-api";
import { ImageFileUpload } from "../../reusable/components/image-file-upload";

export const PatternModify = ({
  item,
  onSubmitted = noop,
}: ModalFormProps<Pattern>) => {
  const {
    mutateAsync: savePattern,
    isLoading,
    error: submitError,
  } = useMutation(item ? updatePattern : createPattern);
  const queryClient = useQueryClient();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...item } });
  const [imageFile, setImageFile] = useState<File>();
  const imageUrl = typeof item?.image === "string" ? item?.image : undefined;
  const errorMessage = useMemo(
    () =>
      submitError
        ? (submitError as AxiosError<BaseResponseData<any>>)?.response?.data
            ?.message
        : Object.values(errors).join(", "),
    [errors, submitError]
  );

  const onSubmit: SubmitHandler<Pattern> = (values) => {
    savePattern(
      { ...values, id: item?.id ?? 0, image: imageFile },
      {
        onSuccess: () => queryClient.invalidateQueries(PATTERN_QUERY_KEY),
      }
    ).then((data) => {
      reset();
      onSubmitted(data);
    });
  };

  const fields = [
    {
      name: "name",
      label: "Nama pola",
    },
    {
      name: "description",
      label: "Deskripsi",
    },
    {
      name: "size",
      label: "Ukuran",
    },
  ];

  const renderField = (
    { name, label }: { name: string; label: string },
    index: number
  ) => (
    <Grid item key={`${name}-${index}`}>
      <Controller
        {...{
          control,
          name,
          render: ({ field }) => (
            <TextField
              {...{
                variant: "outlined",
                label,
                fullWidth: true,
                ...field,
              }}
            />
          ),
        }}
      />
    </Grid>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} direction="column">
        {fields.map(renderField)}
        <Grid item>
          <ImageFileUpload imageUrl={imageUrl} onChange={setImageFile} />
        </Grid>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Typography color="error" variant="body1" textAlign="center">
            {Object.values(errors).join(", ") || errorMessage}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ ml: "auto" }}
            disabled={isLoading}
          >
            {isLoading ? "Sedang menyimpan..." : "Simpan"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
