import api from "../reusable/api/api";
import { BaseResponseData } from "../reusable/api/api.types";
import { Pattern } from "../pattern/pattern-api";
import { Material } from "../material/material-api";
import { WorkingProcess } from "../working-process/working-process-api";

export type Product = {
  id: number;
  name: string;
  description: string;
  image?: string | File;
  pattern: Pattern;
  material: Material;
  processes: WorkingProcess[];
};

export const PRODUCT_QUERY_KEY = "products";

export const PRODUCT_PATH = "/products";

const mapProductToFormData = (request: Product) => {
  const formData = new FormData();
  formData.append("name", request.name);
  formData.append("description", request.description);
  formData.append("pattern_id", String(request.pattern.id));
  formData.append("material_id", String(request.material.id));
  formData.append(
    "working_process_id",
    String(request.processes.map((process) => process.id).join(","))
  );
  if (request.image) {
    formData.append("image", request.image);
  }
  return formData;
};

export const getProducts = () =>
  api
    .get<BaseResponseData<Product[]>>(PRODUCT_PATH)
    .then((response) => response.data?.data ?? []);

export const createProduct = (request: Product) => {
  return api
    .post<BaseResponseData<Product>>(
      PRODUCT_PATH,
      mapProductToFormData(request),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => response.data?.data);
};

export const updateProduct = (request: Product) =>
  api
    .post<BaseResponseData<Product>>(
      `${PRODUCT_PATH}/${request.id}?XDEBUG_SESSION_START=PHPSTORM`,
      mapProductToFormData(request),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => response.data?.data);

export const deleteProduct = (id: number): Promise<void> =>
  api.delete(`${PRODUCT_PATH}/${id}`).then((response) => response.data);
