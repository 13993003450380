import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import {
  createWorkingProcess,
  updateWorkingProcess,
  WORKING_PROCESS_QUERY_KEY,
  WorkingProcess,
} from "../working-process-api";
import { ModalFormProps } from "../../reusable/types";
import { noop } from "lodash";
import { useMemo } from "react";
import { AxiosError } from "axios";
import { BaseResponseData } from "../../reusable/api/api.types";

export const WorkingProcessModify = ({
  item,
  onSubmitted = noop,
}: ModalFormProps<WorkingProcess>) => {
  const {
    mutateAsync: saveWorkingProcess,
    isLoading,
    error: submitError,
  } = useMutation(item ? updateWorkingProcess : createWorkingProcess);
  const queryClient = useQueryClient();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { ...item } });

  const errorMessage = useMemo(
    () =>
      submitError
        ? (submitError as AxiosError<BaseResponseData<any>>)?.response?.data
            ?.message
        : Object.values(errors).join(", "),
    [errors, submitError]
  );

  const onSubmit: SubmitHandler<WorkingProcess> = (values) => {
    saveWorkingProcess(
      { ...values, id: item?.id ?? 0 },
      {
        onSuccess: () =>
          queryClient.invalidateQueries(WORKING_PROCESS_QUERY_KEY),
      }
    ).then((data) => {
      reset();
      onSubmitted(data);
    });
  };

  const fields = [
    {
      name: "name",
      label: "Nama proses",
    },
    {
      name: "description",
      label: "Deskripsi",
    },
    {
      name: "cost",
      label: "Biaya per proses",
    },
  ];

  const renderField = (
    { name, label }: { name: string; label: string },
    index: number
  ) => (
    <Grid item key={`${name}-${index}`}>
      <Controller
        {...{
          control,
          name,
          render: ({ field }) => (
            <TextField
              {...{
                variant: "outlined",
                label,
                fullWidth: true,
                ...field,
              }}
            />
          ),
        }}
      />
    </Grid>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} direction="column">
        {fields.map(renderField)}

        <Grid item container justifyContent="space-between" alignItems="center">
          <Typography color="error" variant="body1" textAlign="center">
            {Object.values(errors).join(", ") || errorMessage}
          </Typography>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ ml: "auto" }}
            disabled={isLoading}
          >
            {isLoading ? "Sedang menyimpan..." : "Simpan"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
