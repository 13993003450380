import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL, TOKEN_NAME } from "../constants";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.defaults.headers.post["Content-Type"] = "application/json";

instance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const token = Cookies.get(TOKEN_NAME);
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      Cookies.remove(TOKEN_NAME);
    }
    return Promise.reject(error);
  }
);

export default instance;
